<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="创建字典"
      class="px-5 py-3 mb-5"
    >
      <div class="my-5">
        <v-btn
          color="success"
          @click="createFormType"
        >
          <v-icon left>
            fa fa-plus
          </v-icon>
          创建字典
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>#</th>
            <th>名称</th>
            <th>ID</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>1</td>
            <td>字典名称</td>
            <td>1e70662b-d765-4fbd-8e2a-b459fb3cf5a6</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="success"
              >
                <v-icon
                  small
                  left
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div>
    </base-material-card>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">创建分类</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="分类名称"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="分类代码"
                  hint="example of helper text only on focus"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  :items="['顶级分类']"
                  label="上级分类"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="排序"
                  hint="排序数值越大越靠后"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="分类介绍"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="dialog = false"
          >
            关闭
          </v-btn>
          <v-btn
            color="success"
            @click="dialog = false"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
      }
    },
    methods: {
      createFormType () {
        // this.$swal({
        //   title: '表单验证未通过',
        //   text: '请查看红色表单项与提示',
        //   icon: 'warning',
        //   confirmButtonText: '知道了',
        // })
        this.dialog = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
